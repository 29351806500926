import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://123bde4bfac58dae4d3659051b93e698@o4506353561305088.ingest.sentry.io/4506353563926528",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import Alpine from 'alpinejs'; window.Alpine = Alpine; Alpine.start();
import { init } from 'astro/virtual-modules/prefetch.js';init()